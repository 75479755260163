import type { AppProps } from 'next/app'
import localFont from 'next/font/local'

import { Inter } from 'next/font/google'
import Script from 'next/script'
import { ToastContainer } from 'react-toastify'
import { useMedia } from 'react-use'
import { SharedLoadingIndicatorContextProvider, SharedProgressLoadingIndicator } from 'shared-loading-indicator'
import { PageNavigationLoadingTracker } from '../components/PageNavigationLoadingTracker'
import '../styles/globals.sass'
import { api } from '../utilities/api'

const inter = Inter({
	subsets: ['latin', 'latin-ext'],
})

const georgia = localFont({
	src: [
		{
			path: '../../public/fonts/georgia-webfont.woff2',
			weight: '400',
		},
	],
})

function MyApp({ Component, pageProps }: AppProps) {
	const theme = useMedia('(prefers-color-scheme: dark)', false) ? 'dark' : 'light'

	return (
		<>
			<style jsx global>{`
				:root {
					--font-georgia: ${georgia.style.fontFamily};
					--font-inter: ${inter.style.fontFamily};
				}
			`}</style>
			<Script id="gtm-tracking" strategy="afterInteractive">{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','G-TCG3V0ZQ1S')`}</Script>

			<SharedLoadingIndicatorContextProvider>
				<PageNavigationLoadingTracker />
				<SharedProgressLoadingIndicator />
				<ToastContainer theme={theme} />
				<Component {...pageProps} />
			</SharedLoadingIndicatorContextProvider>
		</>
	)
}

export default api.withTRPC(MyApp)
